export const thirdPartyDict = {
	caviar: {
		name: 'Caviar',
		redirectImgSrc: 'https://i.imgur.com/2GrmDIr.png',
		color: '#f87242',
	},
	toast: {
		name: 'Toast',
		redirectImgSrc: 'https://i.imgur.com/2GrmDIr.png',
		color: '#ff4c00',
	},
	doordash: {
		name: 'DoorDash',
		redirectImgSrc: 'https://i.imgur.com/2GrmDIr.png',
		color: '#eb1700',
	},
	ubereats: {
		name: 'Uber Eats',
		redirectImgSrc: 'https://i.imgur.com/2GrmDIr.png',
		color: '#000000',
	},
	chownow: {
		name: 'ChowNow',
		redirectImgSrc: 'https://i.imgur.com/2GrmDIr.png',
		color: '#fe4f57',
	},
	whole_foods: {
		name: 'Whole Foods Market',
		redirectImgSrc: 'https://i.imgur.com/2GrmDIr.png',
		color: '#fe4f57',
	},
	grubhub: {
		name: 'GrubHub',
		redirectImgSrc: 'https://i.imgur.com/2GrmDIr.png',
		color: '#ff8000',
	},
}
